import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  // transform(value: string): string {
  //   if (value !== null && value != undefined) {
  //     let legalPhone: string;
  //
  //     const dataString = (string) => {
  //       const regex = /[0-9/A-Z/a-z/ /+/]/g;
  //       const letters = string.match(regex);
  //       return letters.join('');
  //     };
  //
  //     if (dataString(value).startsWith('+')) {
  //       legalPhone = dataString(value).slice(1, dataString(value).length);
  //     } else {
  //       legalPhone = dataString(value);
  //     }
  //
  //     if (legalPhone.length > 12) {
  //       legalPhone = legalPhone.slice(0, 12);
  //     }
  //
  //     if (legalPhone.startsWith('998') && legalPhone.length == 12) {
  //       return legalPhone?.replace(
  //         /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/,
  //         '+$1 ($2) $3-$4-$5'
  //       );
  //     } else {
  //       if (legalPhone.length >= 9) {
  //         legalPhone = legalPhone.slice(0, 9);
  //       }
  //
  //       return legalPhone?.replace(
  //         /(\d{2})(\d{3})(\d{2})(\d{2})/,
  //         '+998 ($1) $2-$3-$4'
  //       );
  //     }
  //   } else {
  //     return '-';
  //   }
  // }


  transform(value: string | null | undefined): string {
    if (!value) {
      return '-';
    }

    const sanitizeInput = (input: string): string => {
      return input.replace(/[^0-9A-Za-z +]/g, '');
    };

    let sanitizedValue = sanitizeInput(value);
    if (sanitizedValue.startsWith('+')) {
      sanitizedValue = sanitizedValue.slice(1);
    }

    const maxLength = 12;
    sanitizedValue = sanitizedValue.slice(0, maxLength);

    if (sanitizedValue.startsWith('998') && sanitizedValue.length === 12) {
      return sanitizedValue.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
    } else {
      const localMaxLength = 9;
      sanitizedValue = sanitizedValue.slice(-localMaxLength);
      return sanitizedValue.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '+998 ($1) $2-$3-$4');
    }
  }
}
